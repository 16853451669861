



















































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { PhysicalsWs } from "@/DataAccess/WebServices/PhysicalsWs";
import { PhysicalTransactions } from "@/models/PhysicalTransactions";

const p = new PhysicalsWs();

@Component({})
export default class Physicals extends Vue {
  transactions: PhysicalTransactions[] = [];
  search = "";
  sortDesc = false;
  sortBy = "contractId";
  loading = false;
  keys = [
    {
      text: "Admin Cost",
      value: "adminCost",
    },
    {
      text: "Advance Level Price",
      value: "advanceLevelPrice",
    },
    {
      text: "Advance Payment Market Warning",
      value: "advancePaymentMarketWarning",
    },
    {
      text: "Advance Payment Percentage",
      value: "advancePaymentPercentage",
    },
    {
      text: "Bank",
      value: "bank",
    },
    {
      text: "Basis Value",
      value: "basisValue",
    },
    {
      text: "Company Interest",
      value: "companyInterest",
    },
    {
      text: "Company Storage",
      value: "companyStorage",
    },
    {
      text: "Contract Id",
      value: "contractId",
    },
    {
      text: "Created By",
      value: "createdBy",
    },
    {
      text: "Dealer",
      value: "dealer",
    },
    {
      text: "Instrument Id",
      value: "instrumentId",
    },
    {
      text: "Inventory Date",
      value: "inventoryDate",
    },
    {
      text: "Last Date of Pricing",
      value: "lastDateOfPricing",
    },
    {
      text: "Location",
      value: "locationId",
    },
    {
      text: "Location Diff",
      value: "locationDifferential",
    },
    {
      text: "Premium Price",
      value: "premiumPrice",
    },
    {
      text: "Price",
      value: "price",
    },
    {
      text: "Safex Cost",
      value: "safexCost",
    },
    {
      text: "Safex Price",
      value: "safexPrice",
    },
    {
      text: "Transaction Tonnes",
      value: "transactionTonnes",
    },
  ];

  async mounted(): Promise<void> {
    this.loading = true;
    const transactions = await p
      .GetAllPhysicalTransactions("2022-01-15")
      .catch((err) => {
        this.loading = false;
        return Promise.reject(err);
      });
    this.transactions = transactions;
    this.loading = false;
  }
}
