/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import { AxiosError } from "axios";
export interface login {
  [field: string]: string | null;
  email: string | null;
  password: string | null;
}
export interface register {
  [field: string]: string | null;
  password: string | null;
  passwordConfirm: string | null;
  email: string | null;
}
export interface loading {
  register: boolean;
  login: boolean;
  reset: boolean;
}
@Module({
  dynamic: true,
  namespaced: true,
  name: "User",
  store,
})
class User extends VuexModule {
  loading: loading = {
    register: false,
    login: false,
    reset: false,
  };
  loginCredentials: login = {
    password: null,
    email: null,
  };
  registerCredentials: register = {
    password: null,
    email: null,
    passwordConfirm: null,
  };
  accessToken: string | null = null;
  authenticated = false;
  expiration: string | null = null;

  get getLoading() {
    return this.loading;
  }

  get getLoginCredentials(): login {
    return this.loginCredentials;
  }
  get getRegisterCredentials(): register {
    return this.registerCredentials;
  }
  @Mutation
  setLoginCredentials(login: { [key: string]: string | null }) {
    this.loginCredentials = {
      ...this.loginCredentials,
      ...login,
    };
  }
  @Mutation
  setRegisterCredentials(register: { [key: string]: string | null }) {
    this.registerCredentials = {
      ...this.registerCredentials,
      ...register,
    };
  }
  get getLoggedIn(): {
    expiration: string | null;
    accessToken: string | null;
    authenticated: boolean;
  } {
    return {
      accessToken: this.accessToken,
      expiration: this.expiration,
      authenticated: this.authenticated,
    };
  }
  @Mutation
  setLoggedIn({
    expiration,
    accessToken,
    user,
  }: {
    expiration: string;
    accessToken: string;
    user: string | null;
  }) {
    this.accessToken = accessToken;
    this.expiration = expiration;
    this.authenticated = true;

    localStorage.setItem(
      "Physicals/login",
      JSON.stringify({
        accessToken: this.accessToken,
        expiration: this.expiration,
        authenticated: this.authenticated,
        user: user,
      })
    );
  }

  @Mutation
  removeLoggedIn() {
    localStorage.removeItem("Physicals/login");
    this.accessToken = null;
    this.expiration = null;
    this.authenticated = false;
  }
  get isLoggedIn(): boolean {
    //add time check here for timestamp?
    return this.authenticated;
  }

  @Action({ rawError: true })
  /* async requestReset(email: string): Promise<void> {
    try {
      const res = await axiosNoAuth
        .post("/api/Authenticate/ResetPassword", {
          email: email,
        })
        .catch((err) => {
          //check types of errors heree
          return Promise.reject(err);
        });

      ////console.log("Done calling login", res.data);
    } catch (err) {
      ////console.log("Error with login: ", err);
      return Promise.reject(err);
    }
  } */
  @Action({ rawError: true })
  async tryLogin(): Promise<void> {
    try {
      let params: { [key: string]: any } = {};
      params = {
        grant_type: "password",
        username: this.getLoginCredentials.email,
        password: this.getLoginCredentials.password,
      };

      const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join("&");
      this.loading.login = true;
      const res = await axiosNoAuth
        .post("/token", data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .catch((err) => {
          //check types of errors here
          return Promise.reject(err);
        });
      console.log("accessToken", res.data.access_token);
      console.log("expiration", res.data.expires);
      this.setLoggedIn({
        accessToken: res.data.access_token,
        expiration: res.data.expires,
        user: this.getLoginCredentials.email,
      });
      ////console.log("Done calling login", res.data);s
    } catch (err) {
      ////console.log("Error withlogin: ", err);
      return Promise.reject(err);
    } finally {
      this.loading.login = false;
    }
  }
  @Action({ rawError: true })
  async tryRegister(): Promise<void> {
    try {
      this.loading.register = true;
      const res = await axiosNoAuth
        .post("/api/Account/Register", {
          userName: this.getRegisterCredentials.email,
          password: this.getRegisterCredentials.password,
          confirmPassword: this.getRegisterCredentials.passwordConfirm,
        })
        .catch((err) => {
          //check types of errors heree
          if (err.isAxiosError) {
            const temp = err as AxiosError;
            if (temp.response)
              ////console.log( "Axios register error: ",Object.keys(temp.response),temp.response);
              return Promise.reject(temp);
          }
          return Promise.reject(err);
        });
      ////console.log("Done calling register", res.data);
    } catch (err) {
      ////console.log("Error with register: ", err);
      return Promise.reject(err);
    } finally {
      this.loading.register = false;
    }
  }
}
export default getModule(User);
