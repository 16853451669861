import { RealTimePrice } from "@/models/RootObjects/RealTimePricesData";
import store from "@/store";
import { DataTableHeader } from "vuetify";

import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";

@Module({ dynamic: true, namespaced: true, name: "Dash", store })
class Dash extends VuexModule {
  appDrawer = false;
  commodity: {
    items: RealTimePrice[];
    selectedHeaders: DataTableHeader[];
    deletedRows: RealTimePrice[];
    deletedGroups: string[];
  } = {
    items: [],
    selectedHeaders: [
      {
        text: "Contract",
        value: "ContractDate",
        align: "start",
      },
      {
        text: "Movement",
        value: "Movement",
        align: "start",
      },
      {
        text: "Bid",
        align: "start",
        value: "LastBidPrice",
      },
      {
        text: "Offer",
        value: "LastOfferPrice",
        align: "start",
      },
      {
        text: "Last",
        align: "start",
        value: "LastTradePrice",
      },
    ],
    deletedRows: [],
    deletedGroups: [],
  };
  get getAppDrawer(): boolean {
    return this.appDrawer;
  }
  @Mutation
  setAppDrawer(val: boolean) {
    this.appDrawer = val;
  }
  get getCommodityHeaders(): DataTableHeader[] {
    return this.commodity.selectedHeaders;
  }
  get getCommodityItems(): RealTimePrice[] {
    return this.commodity.items;
  }
  get getCommodityDeletedGroups(): string[] {
    return this.commodity.deletedGroups;
  }
  get getDeletedRealTimePrices(): RealTimePrice[] {
    return this.commodity.deletedRows;
  }
  @Mutation
  setAllItems(arr: RealTimePrice[]) {
    this.commodity.items = arr;
  }
  @Mutation
  addSingleItem(arr: RealTimePrice) {
    this.commodity.items.push(arr);
  }
  @Mutation
  removeSingleItem(item: RealTimePrice) {
    this.commodity.items.filter((e) => e.CustomID != item.CustomID);
  }
  @Mutation
  setCommoditySelectedHeaders(arr: DataTableHeader[]) {
    this.commodity.selectedHeaders = arr;
  }
  @Mutation
  setCommoditySelectedHeader(head: DataTableHeader) {
    const found = this.commodity.selectedHeaders.findIndex(
      (e) => e.value == head.value
    );
    if (found == -1) {
      this.commodity.selectedHeaders.push(head);
    } else {
      this.commodity.selectedHeaders = this.commodity.selectedHeaders.filter(
        (e) => e.value != head.value
      );
    }
  }
  @Mutation
  clearDeletedExpiryInstrument(arr: RealTimePrice[]) {
    arr.forEach((e) => {
      const temp = this.commodity.deletedRows.find((er) => er == e);
      if (temp != undefined) {
        this.commodity.deletedRows = this.commodity.deletedRows.filter(
          (del) => del != temp
        );
      }
    });
    //console.log("remove: ", arr)
  }
  @Mutation
  addDeletedExpiryInstrument(e: RealTimePrice) {
    this.commodity.deletedRows.push(e);
  }

  @Mutation
  addDeletedPricingGroup(val: string) {
    this.commodity.deletedGroups.push(val);
  }
  @Mutation
  removeDeletedPricingGroup(instrus: string[]) {
    //console.log("To be readd to list: ", instrus, this.commodity.deletedGroups);
    instrus.forEach((e) => {
      const find = this.commodity.deletedGroups.find((el) => el == e);
      if (find) {
        this.commodity.deletedGroups = this.commodity.deletedGroups.filter(
          (el) => el != e
        );
      }
    });
    //console.log("remove:", instrus);
  }
  /*  @Mutation
  removeSingleDeletedPricingGroup(instrus: string) {
    const find = this.commodity.deletedGroups.find(el => el.name == instrus);
      if (find)
        this.commodity.deletedGroups = this.commodity.deletedGroups.filter(el => el.name != instrus)
        console.log("remove:", instrus)
  } */
  // @Mutation
  // addDeletedPricingGroupItems(item: { name: string; items: RealTimePrice[] }) {
  //   console.log("Adding these items: ", item.items, item.name);
  //   const temp = this.commodity.deletedGroups.find((e) => e.name == item.name);
  //   if (temp) {
  //     temp.items = item.items;
  //   } else {
  //     this.commodity.deletedGroups.push(item);
  //   }
  // }
  @Mutation
  addCommoditySelectedHeaders(val: DataTableHeader) {
    this.commodity.selectedHeaders.push(val);
  }
}

export default getModule(Dash);
