import { Deliveries } from "@/models/Deliveries";
import { Delivery } from "@/models/Delivery";
import { axiosInstance } from "@/plugins/axios";

export class DeliveriesWs {
  public async GetAllDeliveries(): Promise<Deliveries[]> {
    const base = `/api/Delivery/getallDeliverys`;
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on GetAllDeliveries", err);
      return Promise.reject(err);
    });
    //console.log("Res for GetAllDeliveries", res);
    return Promise.resolve(res.data);
  }

  public async GetDelivery(id: string): Promise<Delivery[]> {
    const base = `/api/Delivery/getDelivery?id=${id}`;
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on GetDelivery", err);
      return Promise.reject(err);
    });
    //console.log("Res for GetDelivery", res);
    return Promise.resolve(res.data);
  }

  public async GetPhysicalBuyDelivery(id: string): Promise<Delivery[]> {
    const base = `/api/Delivery/getPhysicalBuyDelivery?id=${id}`;
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on GetPhysicalBuyDelivery", err);
      return Promise.reject(err);
    });
    //console.log("Res for GetPhysicalBuyDelivery", res);
    return Promise.resolve(res.data);
  }

  public async GetPhysicalSellDelivery(id: string): Promise<Delivery[]> {
    const base = `/api/Delivery/getPhysicalSellDelivery?id=${id}`;
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on GetPhysicalSellDelivery", err);
      return Promise.reject(err);
    });
    //console.log("Res for GetPhysicalSellDelivery", res);
    return Promise.resolve(res.data);
  }
}
