

















































































































import Auth, { login } from "@/store/modules/Auth";
import { Component, Vue } from "vue-property-decorator";
import { goToPage } from "@/scripts/navigation";
import updateNotif from "@/components/pwa/updateAvailNotif.vue";
import RegisterModal from "@/components/Login/RegisterModal.vue";
import ResetPassModal from "@/components/Login/ResetPassModal.vue";

@Component({
  components: {
    updateNotif,
    RegisterModal,
    ResetPassModal,
  },
})
export default class Login extends Vue {
  errorMsg = "Incorrect Email or Password. Please Try Again.";
  alert = false;
  valid = false;
  loading = false;
  openModalRegister = false;
  openModalReset = false;
  resetBusy = false;
  showPassword = false;
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    counter: (value: string): boolean | string =>
      value.length <= 20 || "Max 20 characters",

    email: (value: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail";
    },
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return pattern.test(value) || "Requires atleast 1 uppercase letter";
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return pattern.test(value) || "Requires atleast 1 lowercase letter";
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return pattern.test(value) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Requires atleast 1 special character";
    },
  };

  get getCredentials(): login {
    return Auth.getLoginCredentials;
  }

  updateEmail(email: string): void {
    Auth.setLoginCredentials({ email: email });
  }

  updatePassword(pass: string): void {
    Auth.setLoginCredentials({ password: pass });
  }

  openRegister(): void {
    this.openModalRegister = true;
  }

  openReset(): void {
    this.openModalReset = true;
  }

  async tryLogin(): Promise<void> {
    try {
      this.alert = false;
      this.loading = true;
      await Auth.tryLogin();
    } catch (err) {
      this.alert = true;
      this.loading = false;
    } finally {
      if (Auth.isLoggedIn) {
        this.alert = false;
        this.loading = false;
        goToPage("dash.home");
      }
    }
  }

  /*  async submitForgotEmail(): Promise<void> {
    this.resetBusy = true;
    await Auth.requestReset(this.emailForgotPasssword).catch((err) => {
      ////console.log("Error submitting resetPassword", err);
      return Promise.reject(err);
    });
    this.errorMsg = "Reset Password Email Sent!";
    this.alert = true;

    this.resetBusy = false;
    this.modalForgotPassword = false;
  } */
}
