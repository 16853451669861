



























import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavDrawer extends Vue {
  deferredPrompt: Event | null = null;
  showIos = true;
  //  Detects if device is on iOS
  isIos(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    //console.log("User agent: ", userAgent);
    return false;
    // return /^(iphone|ipad|ipod)/.test(userAgent);
  }
  // Detects if device is in standalone mode
  isInStandaloneMode = (): boolean =>
    "standalone" in window.navigator && (window.navigator as any).standalone;
  get getIosBanner(): boolean {
    if (this.isIos() && !this.isInStandaloneMode() && this.showIos == true) {
      //console.log("Show IOS install prompts");
      return true;
    } else {
      return false;
    }
  }
  created(): void {
    window.addEventListener("beforeinstallprompt", (e: Event) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  }
  dismiss(): void {
    this.deferredPrompt = null;
  }
  install(): void {
    // if(this.deferredPrompt as BeforeInstall)
    (this.deferredPrompt as any).prompt();
    //console.log("Defered: ", this.deferredPrompt);
  }
}
