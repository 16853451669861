










































































































































































































































































































































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { Delivery } from "@/models/Delivery";

@Component({})
export default class ContractsOpenRowModal extends Vue {
  @PropSync("openContextModal") openRowModal!: boolean;
  @Prop() readonly data!: { data: Delivery[]; title: string };
  /* display = false;
  orientation = this.orientatonChangeCheck(); */

  /* @Watch("orientation", {
    immediate: true,
  })
  onOrientationChange(): void {
    if (this.openRowModal == true) {
      this.orientatonCheck();
    }
  }

  @Watch("openRowModal", {
    immediate: true,
  })
  onChanged(): void {
    if (this.openRowModal == true) {
      this.orientatonCheck();
    }
  }

  orientatonCheck(): void {
    if (window.innerHeight > window.innerWidth) {
      //Device in Portrait
      this.display = false;
    } else {
      //Device in Landscape
      this.display = true;
    }
  }

  orientatonChangeCheck(): void {
    const check = () => {
      if (screen.orientation.type == "portrait-primary") {
        this.orientatonCheck();
        this.display = false;
      } else if (screen.orientation.type == "landscape-primary") {
        this.orientatonCheck();
        this.display = true;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      window.addEventListener("orientationchange", check, false);
    };

    screen.orientation.onchange = check;
  } */
}
