import { Contract } from "@/models/Contracts";
import { axiosInstance } from "@/plugins/axios";

export class ContractWs {
  public async GetAllContracts(date: string): Promise<Contract[]> {
    const base = `/api/Contracts/getContractReport?date=${date}`;
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on GetAllContracts", err);
      return Promise.reject(err);
    });
    //console.log("Res for GetAllContracts", res);
    return Promise.resolve(res.data);
  }

  public async GetAllCompleteContracts(date: string): Promise<Contract[]> {
    const base = `/api/Contracts/getCompleteContractReport?date=${date}`;
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on GetAllContracts", err);
      return Promise.reject(err);
    });
    //console.log("Res for GetAllContracts", res);
    return Promise.resolve(res.data);
  }
}
