







































import { Component, Vue, Watch } from "vue-property-decorator";
import NotificationSystem from "@/store/modules/NotificationSystem";
import { notificationType } from "models";
import { notificationEnum } from "@/notificationEnum";

@Component
export default class GlobalNotifications extends Vue {
  get displayAnyNotifs(): boolean {
    return this.open;
  }
  currentNotif: null | notificationType = null;
  open = false;
  get getCurrentNotification(): notificationType | null {
    return NotificationSystem.getCurrentNotif;
  }
  closeNotif(notification: notificationType): void {
    this.open = false;
    setTimeout(
      NotificationSystem.clearCurrentNotifWithCheck,
      300,
      notification
    );
  }
  @Watch("getCurrentNotification", {
    immediate: true,
  })
  currentNotifChanged(
    newNotif: notificationType | null,
    oldNotif: notificationType | null | undefined
  ): void {
    // console.log("Current Notif Changed", newNotif, oldNotif);
    if (newNotif == null) {
      this.open = false;
      this.currentNotif = newNotif;
      setTimeout(() => {
        this.open = true;
      }, 200);
    } else {
      this.open = false;
      this.currentNotif = newNotif;
      setTimeout(() => {
        this.open = true;
      }, 200);
    }
  }

  mounted(): void {
    // NotificationSystem.addNotification({
    //   message: "Test error!!",
    //   color: "error",
    //   icon: "mdi-alert",
    // });
    // NotificationSystem.addNotification({
    //   message: "Success!!!!",
    //   color: "success",
    //   icon: "mdi-check",
    // });
  }
}
