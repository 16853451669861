






















































































































































































































import { Component, Vue, PropSync } from "vue-property-decorator";
import Auth from "@/store/modules/Auth";
import { goToPage } from "@/scripts/navigation";

@Component
export default class RegisterModal extends Vue {
  valid = false;
  showPassword = false;
  loading = false;
  @PropSync("open")
  openModalRegister!: boolean;
  pMatch = false;
  termsAlert = false;
  rules = {
    min: (v: string): boolean | string =>
      (v && v.length >= 5) || "Requires a minimum length of 5",
    lower: (v: string): boolean | string =>
      /.(?=.*[a-z])/.test(v) || "Requires atleast 1 lowercase letter",
    upper: (v: string): boolean | string =>
      /(?=.*[A-Z])/.test(v) || "Requires atleast 1 uppercase letter",
    num: (v: string): boolean | string =>
      /(?=.*\d)/.test(v) || "Requires atleast 1 number",
    special: (v: string): boolean | string =>
      /([!@$%])/.test(v) || "Requires atleast 1 special character",
    email: (v: string): boolean | string =>
      /.+@.+\..+/.test(v) || "E-mail must be valid",
  };
  passRules = [
    this.rules.min,
    this.rules.lower,
    this.rules.upper,
    this.rules.num,
    this.rules.special,
  ];
  get confirmPassRules(): ((v: string) => string | boolean)[] {
    const temp = this.passRules;
    // temp.push(this.passwordConfirmationRule);
    return temp.concat(this.passwordConfirmationRule);
  }
  register = {
    name: "",
    lastName: "",
    cellnumber: "",
    email: "",
    password: "",
    confirm: "",
    organisation: "",
    occupation: "",
    province: "",
    existing_client: false,
    terms: false,
  };
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get getCredentials() {
    return this.register;
  }
  get passwordConfirmationRule(): (v: string) => string | boolean {
    return () =>
      (this.getCredentials.password === this.getCredentials.confirm &&
        this.getCredentials.password != null) ||
      `Password must match ${this.getCredentials.password}`;
  }

  updateEmail(email: string): void {
    //////console.log("Test :", email);
    Auth.setRegisterCredentials({ email: email });
    Auth.setLoginCredentials({ email: email });
  }

  updatePassword(pass: string): void {
    //////console.log("Test :", pass);
    Auth.setRegisterCredentials({ password: pass });
    Auth.setLoginCredentials({ password: pass });
  }

  updateConfirmPassword(confirmPass: string): void {
    //////console.log("Test :", pass);
    Auth.setRegisterCredentials({ passwordConfirm: confirmPass });
  }

  async registerAccount(): Promise<void> {
    try {
      this.loading = true;
      await Auth.tryRegister();
      this.loading = false;
      this.openModalRegister = false;
      await Auth.tryLogin();
    } catch (err) {
      this.loading = false;
      console.log("Register Account CATCH: ", err);
      return Promise.reject(err);
    } finally {
      if (Auth.isLoggedIn) {
        this.openModalRegister = false;
        this.loading = false;
        goToPage("dash.home");
      }
    }
  }

  closeRegister(): void {
    this.openModalRegister = false;
    this.register.name = "";
    this.register.lastName = "";
    this.register.cellnumber = "";
    this.register.email = "";
    this.register.password = "";
    this.register.confirm = "";
    this.register.organisation = "";
    this.register.occupation = "";
    this.register.province = "";
    this.register.existing_client = false;
    this.register.terms = false;
  }
}
