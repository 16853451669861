














































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { ClientWs } from "@/DataAccess/WebServices/ClientWs";
import { ClientInfo } from "@/models/ClientInfo";

const cl = new ClientWs();

@Component({})
export default class Client_Info extends Vue {
  clientInfo: ClientInfo[] = [];
  search = "";
  sortDesc = false;
  sortBy = "contractId";
  loading = false;
  keys = [
    /* { text: "Admin Costs", value: "adminCosts" },
    { text: "Basis Amount", value: "basisAmount" },
    { text: "Client Name", value: "clientName" },
    { text: "Completed", value: "complete" },
    { text: "Contract Date", value: "contractDate" },
    { text: "Contract", value: "contractId" },
    { text: "Contract Number", value: "contractNumber" },
    { text: "Contract Price", value: "contractPrice" },
    { text: "Date Priced", value: "datePriced" },
    { text: "Finance Admin Fee", value: "financeAdminFee" },
    { text: "Future Price", value: "futurePrice" },
    { text: "Grade", value: "grade" },
    { text: "Interest", value: "interest" },
    { text: "Interest Costs", value: "interestCosts" },
    { text: "Interest per Tonnes", value: "interestPerTonnes" },
    { text: "Interest to Date", value: "interestToDate" },
    { text: "Inventory Type", value: "inventoryType" },
    { text: "Inventory Type Name", value: "inventoryTypeName" },
    { text: "JSE Month", value: "jseMonth" },
    { text: "JSE Spread", value: "jseSpread" },
    { text: "Location Diff", value: "locationDif" },
    { text: "Type", value: "physicalInstructionType" },
    { text: "Premium", value: "premium" },
    { text: "Remaining Tonnes", value: "remainingTonnes" },
    { text: "Storage Costs", value: "storageCosts" },
    { text: "Storage per Tonnes", value: "storagePerTonnes" },
    { text: "Storage to Date", value: "storageToDate" },
    { text: "Tonnes", value: "tonnes" },
    { text: "Tonnes Delivered", value: "tonnesDelivered" },
    { text: "Total Storage", value: "totalStorage" },
    { text: "Trader Costs", value: "traderCosts" },
    { text: "Transport Costs", value: "transportCosts" },*/
  ];

  async mounted(): Promise<void> {
    this.loading = true;
    const clientInfo = await cl.GetClientInfo().catch((err) => {
      this.loading = false;
      return Promise.reject(err);
    });
    this.clientInfo = clientInfo;
    this.loading = false;
  }
}
