import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Home from "../components/Home.vue";
import ContactUs from "../components/ContactUs.vue";
import Notifications from "../components/Notifications.vue";
import AppBar from "../components/Appbar.vue";
import NavDrawer from "../components/NavDrawer.vue";
import Auth from "@/store/modules/Auth";
import dayjs from "dayjs";
import { notifications } from "@/hooks/Notifications";

/* import Contracts from "../components/Contracts/Contracts.vue"; */
import Contracts2 from "../components/Contracts/Contracts2.vue";
import History from "../components/Contracts/History.vue";
import Deliveries from "../components/Deliveries.vue";
import Physicals from "../components/PhysicalTransactions.vue";
import ClientInfo from "../components/ClientInfo.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    components: {
      main: Login,
    },
  },
  {
    path: "/dashboard",
    components: {
      main: Dashboard,
      nav: NavDrawer,
      appBar: AppBar,
    },
    children: [
      {
        path: "home",
        name: "dash.home",
        component: Home,
      },
      {
        path: "notifications",
        name: "dash.notifications",
        component: Notifications,
      },
      {
        path: "contact-us",
        name: "dash.contact-us",
        component: ContactUs,
      } /* 
      {
        path: "contracts",
        name: "dash.contracts1",
        component: Contracts,
      }, */,
      {
        path: "contracts2",
        name: "dash.contracts2",
        component: Contracts2,
      },
      {
        path: "history",
        name: "dash.history",
        component: History,
      },
      {
        path: "physicals",
        name: "dash.physicals",
        component: Physicals,
      },
      {
        path: "deliveries",
        name: "dash.deliveries",
        component: Deliveries,
      },
      {
        path: "clientInfo",
        name: "dash.clientInfo",
        component: ClientInfo,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
  mode: "hash",
});

/* router.beforeEach((to, from, next) => {
  // if (AuthModule.isLoggedIn == false) {
  Auth.checkLocalStorage();

  if (to.name !== "Login" && Auth.getValidLogin == false) {
    next({
      name: "Login",
      params: {
        from: from == undefined ? "" : from.path,
      },
    });
  } else if (to.name == "Login" && Auth.getValidLogin == true) {
    next({
      name: "dash.home",
    });
  } else next();
}); */

router.beforeEach((to, from, next) => {
  document.title = "Apogee";

  /* if (
    to.name === "forgotPassword" ||
    to.name === "ConfirmEmail" ||
    to.name === "registration" ||
    to.name === "initialApply"
  ) {
    next();
    return;
  } */

  // if (AuthModule.isLoggedIn == false) {
  const key = localStorage.getItem("Physicals/login");
  if (key != null) {
    Auth.setLoggedIn(JSON.parse(key));
  } else {
    Auth.removeLoggedIn();
  }
  // }

  if (to.name !== "Login" && Auth.isLoggedIn == false) {
    next({
      name: "Login",
      params: {
        from: from == undefined ? "" : from.path,
      },
    });
  } else if (to.name == "Login" && Auth.isLoggedIn == true) {
    next({
      name: "dash.home",
    });
  } else next();
});

export default router;
