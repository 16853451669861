

























































import dayjs from "dayjs";
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";

@Component
export default class Home extends Vue {
  Dayjs = dayjs;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  /* slides = ["First", "Second", "Third"]; */

  username: string | null = null;
  get userDetails(): string | null {
    const key = localStorage.getItem("Physicals/login");
    if (AuthModule.loginCredentials.email != null) {
      this.username = AuthModule.loginCredentials.email;
    } else {
      if (key != null) {
        this.username = JSON.parse(key).user;
      }
    }

    return this.username;
  }
}
