











































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import VueSpeedometer from "vue-speedometer";
import { ContractWs } from "@/DataAccess/WebServices/ContracsWs";
import { DeliveriesWs } from "@/DataAccess/WebServices/DeliveriesWs";
import { Contract } from "@/models/Contracts";
import { Delivery } from "@/models/Delivery";

import ContractsModal2 from "@/components/Contracts/ContractsModal2.vue";

const c = new ContractWs();
const d = new DeliveriesWs();

@Component({
  components: {
    ContractsModal2,
    VueSpeedometer,
  },
})
export default class History extends Vue {
  contracts: Contract[] = [];
  deliveries: Delivery[] = [];
  search = "";
  sortDesc = true;
  sortBy = "contractId";
  date = this.getDate();
  dateMenu = false;
  loading = false;
  deliveryModal = false;
  modalHeaders: {
    text: string;
    value: string;
  }[] = [];
  payments = 0;
  keys = [
    { text: "Instrument", value: "instrument" },
    { text: "Admin Costs", value: "adminCosts" },
    { text: "Basis Amount", value: "basisAmount" },
    { text: "Client Name", value: "clientName" },
    { text: "Completed", value: "complete" },
    { text: "Contract Date", value: "contractDate" },
    { text: "Contract", value: "contractId" },
    { text: "Contract Number", value: "contractNumber" },
    { text: "Contract Price", value: "contractPrice" },
    { text: "Date Priced", value: "datePriced" },
    { text: "Finance Admin Fee", value: "financeAdminFee" },
    { text: "Future Price", value: "futurePrice" },
    { text: "Grade", value: "grade" },
    { text: "JSE Month", value: "jseMonth" },
    { text: "JSE Spread", value: "jseSpread" },
    { text: "Location Diff", value: "locationDif" },
    { text: "Type", value: "physicalInstructionType" },
    { text: "Premium", value: "premium" },
    { text: "Remaining Tonnes", value: "remainingTonnes" },
    { text: "Storage Costs", value: "storageCosts" },
    { text: "Storage per Tonnes", value: "storagePerTonnes" },
    { text: "Storage to Date", value: "storageToDate" },
    { text: "Tonnes", value: "tonnes" },
    { text: "Tonnes Delivered", value: "tonnesDelivered" },
    { text: "Total Storage", value: "totalStorage" },
    { text: "Trader Costs", value: "traderCosts" },
    { text: "Transport Costs", value: "transportCosts" },
  ];
  modalData = {
    deliveries: this.deliveries,
    /* transactionType: "", */
    headers: this.modalHeaders,
    title: "",
  };

  scheduleHeaders = [
    { text: "Instruction No", value: "instructionNumber" },
    { text: "Transporter", value: "transporter" },
    { text: "Pick Up Date", value: "pickUpDate" },
    { text: "Driver", value: "driverFullName" },
    { text: "Driver ID", value: "truckDriverID" },
    { text: "Truck Reg No", value: "truckRegistrationNumber" },
  ];
  paymentHeaders = [
    { text: "Instruction No", value: "instructionNumber" },
    { text: "Transporter", value: "transporter" },
    { text: "Payment Date", value: "paymentDate" },
    { text: "Payment Tonnes", value: "paymentTonnes" },
    {
      text: "Payment",
      value: "paymentCalc",
    },
    { text: "Paid", value: "paid" },
    { text: "Weigh Bridge No", value: "weighBridgeNo" },
  ];
  deliveryHeaders = [
    { text: "Instruction No", value: "instructionNumber" },
    { text: "Transporter", value: "transporter" },
    { text: "Off Load Date", value: "offLoadDate" },
    { text: "Off Load Tonnes", value: "offLoadTonnes" },
    { text: "Weigh Bridge No", value: "weighBridgeNo" },
  ];

  async mounted(): Promise<void> {
    this.getContracts(this.date);
  }

  async getContracts(date: string): Promise<void> {
    this.loading = true;

    const contracts = await c.GetAllCompleteContracts(date).catch((err) => {
      this.loading = false;
      return Promise.reject(err);
    });
    this.contracts = contracts;
    this.loading = false;
  }

  async openModal(title: string, cItem: Contract): Promise<void> {
    if (title == "Delivery Schedule") {
      this.modalData.headers = this.scheduleHeaders;
      this.modalData.title = title;
    } else if (title == "Payments") {
      this.modalData.headers = this.paymentHeaders;
      this.modalData.title =
        title +
        ` (${cItem.contractNumber}, ${cItem.instrument}, Tonnes: ${cItem.tonnes},
Del: ${cItem.tonnesDelivered} )`;
    } else if (title == "Deliveries") {
      this.modalData.headers = this.deliveryHeaders;
      this.modalData.title = (
        title +
        ` (${cItem.contractNumber}, ${cItem.instrument}, Tonnes: ${cItem.tonnes}, Del: ${cItem.tonnesDelivered}
)`
      ).toString();
    }

    const type = cItem.physicalInstructionType;
    const id = cItem.contractId.toString();
    if (type == "Sell") {
      const deliveries = await d.GetPhysicalSellDelivery(id).catch((err) => {
        return Promise.reject(err);
      });
      this.modalData.deliveries = deliveries;
    } else if (type == "Buy") {
      const deliveries = await d.GetPhysicalBuyDelivery(id).catch((err) => {
        return Promise.reject(err);
      });
      this.modalData.deliveries = deliveries;
    }
    this.deliveryModal = true;
  }

  getDate(): string {
    const today = new Date();
    this.date =
      new Date(today).getFullYear().toString() +
      "-" +
      (new Date(today).getMonth() + 1) +
      "-" +
      new Date(today).getDate();
    return this.date;
  }

  @Watch("date", {
    immediate: true,
  })
  onFromDateChanged(): void {
    this.getContracts(this.date);
  }

  /* swipe(i: any): void {
    this.deliveryDialog = true;
    this.activeItem = i;
  } */
}
