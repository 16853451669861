import { PhysicalTransactions } from "@/models/PhysicalTransactions";
import { PhysicalInstructions } from "@/models/PhysicalInstructions";
import { axiosInstance } from "@/plugins/axios";

export class PhysicalsWs {
  public async GetAllPhysicalTransactions(
    date: string
  ): Promise<PhysicalTransactions[]> {
    const base = `/api/Physicals/getAllPhysicalTransactions?date=${date}`;
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on GetAllPhysicalTransactions", err);
      return Promise.reject(err);
    });
    console.log("Res for GetAllPhysicalTransactions", res);
    return Promise.resolve(res.data);
  }
  public async GetAllPhysicalInstructions(
    id: string,
    date: string
  ): Promise<PhysicalInstructions[]> {
    const base = `/api/PhysicalInstructions/getPhysicalInstructions?id=${id}&date=${date}`;
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on GetAllPhysicalTransactions", err);
      return Promise.reject(err);
    });
    //console.log("Res for GetAllPhysicalTransactions", res);
    return Promise.resolve(res.data);
  }
}
