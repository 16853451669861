import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { colors } from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#336E2A",
        secondary: "#8CC63F",
        accent: "#8CC63F",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: colors.amber.darken2,
        background: "#ffffff",
        clearingbg: colors.grey.lighten2,
        weatherbg: colors.blue,
        listbg: colors.grey.lighten4,
        textDMode: "#000000",
        cardbg: colors.grey.lighten4,
      },
      dark: {
        primary: {
          base: "#336E2A",
        },
        secondary: "#8CC63F",
        accent: "#8CC63F",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: colors.grey.darken4,
        clearingbg: colors.grey.darken3,
        weatherbg: colors.blue.darken4,
        listbg: colors.grey.darken3,
        textDMode: "#ffffff",
      },
    },
  },
});
