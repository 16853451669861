

























































































































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { Delivery } from "@/models/Delivery";
import ContractsOpenRowModal from "@/components/Contracts/ContractsOpenRow.vue";

@Component({ components: { ContractsOpenRowModal } })
export default class ContractsModal2 extends Vue {
  @PropSync("open") deliveryModal!: boolean;
  @Prop() readonly data!: {
    deliveries: Delivery[];
    /* transactionType: string; */
    headers: { text: string; value: string };
    title: string;
  };
  display = false;
  orientation = this.orientatonChangeCheck();
  openRowModal = false;
  openRowModalData = { data: this.data.deliveries, title: "" };

  @Watch("orientation", {
    immediate: true,
  })
  onOrientationChange(): void {
    if (this.deliveryModal == true) {
      this.orientatonCheck();
    }
  }

  @Watch("deliveryModal", {
    immediate: true,
  })
  onChanged(): void {
    if (this.deliveryModal == true) {
      this.orientatonCheck();
    }
  }

  orientatonCheck(): void {
    if (window.innerHeight > window.innerWidth) {
      //Device in Portrait
      this.display = false;
    } else {
      //Device in Landscape
      this.display = true;
    }
  }

  orientatonChangeCheck(): void {
    const check = () => {
      if (screen.orientation.type == "portrait-primary") {
        this.orientatonCheck();
        this.display = false;
      } else if (screen.orientation.type == "landscape-primary") {
        this.orientatonCheck();
        this.display = true;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      window.addEventListener("orientationchange", check, false);
    };

    screen.orientation.onchange = check;
  }

  openContextRow(i: Delivery[]): void {
    this.openRowModal = true;
    this.openRowModalData.data = i;
    this.openRowModalData.title = this.data.title;
  }
}
