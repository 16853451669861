import { ClientInfo } from "@/models/ClientInfo";
import { axiosInstance } from "@/plugins/axios";

export class ClientWs {
  public async GetClientInfo(): Promise<ClientInfo[]> {
    const base = `/api/Client/getClient`;
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on GetClientInfo", err);
      return Promise.reject(err);
    });
    //console.log("Res for GetClientInfo", res);
    return Promise.resolve(res.data);
  }
}
