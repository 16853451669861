











































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { DeliveriesWs } from "@/DataAccess/WebServices/DeliveriesWs";
import { Deliveries } from "@/models/Deliveries";

const d = new DeliveriesWs();

@Component({})
export default class DeliveriesTab extends Vue {
  deliveries: Deliveries[] = [];
  search = "";
  sortDesc = false;
  sortBy = "physicalTransactionId";
  loading = false;
  keys = [
    { text: "Client", value: "client" },
    { text: "Contract", value: "contract" },
    { text: "Contract Number", value: "contractNumber" },
    { text: "Created By", value: "createdBy" },
    { text: "Drop Off", value: "dropOff" },
    { text: "Instrument", value: "instrument" },
    { text: "Completed", value: "isComplete" },
    { text: "Location", value: "location" },
    { text: "Physical Transaction", value: "physicalTransactionId" },
    { text: "Pickup", value: "pickup" },
    { text: "Tonnes", value: "tonnes" },
    { text: "Tonnes Delivered", value: "tonnesDelivered" },
    { text: "Tonnes Outstanding", value: "tonnesOutstanding" },
  ];

  async mounted(): Promise<void> {
    this.loading = true;
    const deliveries = await d.GetAllDeliveries().catch((err) => {
      this.loading = false;
      return Promise.reject(err);
    });
    this.deliveries = deliveries;
    this.loading = false;
  }
}
