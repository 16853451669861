


























































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import Dash from "@/store/modules/Dash";
import { goToPage } from "@/scripts/navigation";

@Component
export default class NavDrawer extends Vue {
  // darkmode: boolean | undefined;
  flipDrawer(trust: boolean): void {
    // console.log("Nav flip me please ", trust, this.appDrawer);
    Dash.setAppDrawer(trust);
  }
  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }

  selected = null;
  logout(): void {
    AuthModule.removeLoggedIn();
    goToPage("Login");
  }
  // toggleDarkTheme(): void {
  //   this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  //   localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
  // }
  // mounted(): void {
  //   const theme = localStorage.getItem("dark_theme");
  //   if (theme) {
  //     if (theme == "true") {
  //       this.$vuetify.theme.dark = true;
  //       this.darkmode = true;
  //     } else {
  //       this.$vuetify.theme.dark = false;
  //       this.darkmode = false;
  //     }
  //   }
  // }
}
