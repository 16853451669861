









































import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import { goToPage } from "@/scripts/navigation";
import Dash from "@/store/modules/Dash";

@Component
export default class Appbar extends Vue {
  flipDrawer(): void {
    // console.log("Flip drawer  to: ", !this.appDrawer);
    Dash.setAppDrawer(!this.appDrawer);
  }
  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }

  title = "";
  username: string | null = null;
  get userDetails(): string | null {
    const key = localStorage.getItem("Physicals/login");
    if (AuthModule.loginCredentials.email != null) {
      this.username = AuthModule.loginCredentials.email;
    } else {
      if (key != null) {
        this.username = JSON.parse(key).user;
      }
    }
    return this.username;
  }
  logout(): void {
    AuthModule.removeLoggedIn();
    goToPage("Login");
  }
  @Watch("$route.name", { immediate: true })
  routeChanged(newTitle: string): void {
    switch (newTitle) {
      case "dash.home":
        this.title = "Home";
        break; /* 
      case "dash.express":
        this.title = "Express";
        break;
      case "dash.currencies":
        this.title = "Currencies";
        break;
      case "dash.market-index":
        this.title = "Market Index";
        break;
      case "dash.weather":
        this.title = "Weather Stations Overview";
        break;
      case "dash.precipitation":
        this.title = "Precipitation";
        break;
      case "dash.mtm":
        this.title = "Mark to Market";
        break;
      case "dash.calculator":
        this.title = "Option Calculator";
        break;
      case "dash.notifications":
        this.title = "Notifications";
        break;
      case "dash.commodity":
        this.title = "Commodities";
        break;
      case "dash.itac":
        this.title = "ITAC";
        break; */
      case "dash.contact-us":
        this.title = "Contact Us";
        break;
      /* case "dash.contracts1":
        this.title = "All Contracts 1";
        break; */
      case "dash.contracts2":
        this.title = "All Contracts";
        break;
      case "dash.history":
        this.title = "History";
        break;
      case "dash.clientInfo":
        this.title = "Client Information";
        break;
      case "dash.physicals":
        this.title = "Physicals";
        break;
      case "dash.deliveries":
        this.title = "Deliveries";
        break;
      default:
        this.title = newTitle;
        break;
    }
  }
}
