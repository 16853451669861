import Auth from "@/store/modules/Auth";
import _Vue from "vue";
import axios, { AxiosInstance } from "axios";

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SERVER_BASE,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
export const axiosNoAuth: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SERVER_BASE,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

axiosInstance.interceptors.request.use((restConfig) => {
  const u = Auth.isLoggedIn;
  if (u == true) {
    // is logged in
    const token = Auth.accessToken;
    //console.log("Acces token :", token, u);
    const config = restConfig;
    config.headers.Authorization = "Bearer " + token;
    return config;
  }
  return restConfig;
});

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$axiosInstance = axiosInstance;
    Vue.prototype.$axiosNoAuth = axiosNoAuth;
  },
};
